<template>
  <div class="min-h-screen pt-16 pb-12 flex flex-col bg-violet-600">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0 flex justify-center">
        <RouterLink :to="{name: 'landing'}" class="inline-flex">
          <img class="w-[5.875rem] lg:w-28" src="@/assets/logo-light.svg" alt="trguj.me">
        </RouterLink>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-sm font-semibold text-violet-50 uppercase tracking-wide">
            404
          </p>
          <h1 class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            Stranica ne postoji.
          </h1>
          <p class="mt-2 text-base text-violet-50">
            Ne možemo da pronađemo stranicu koju tražite
          </p>
          <div class="mt-6">
            <RouterLink :to="{name: 'landing'}" class="inline-flex items-center text-base font-medium text-white">
              Nazad na početnu
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </RouterLink>
          </div>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
export default {}
</script>

<style scoped>

</style>